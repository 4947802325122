@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Kaushan+Script&family=Lato:wght@300;400;700&family=Poppins:wght@300;400;500;600;700&display=swap");
html {
  background: rgb(139, 139, 139);
  scroll-behavior: smooth;
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Bebas Neue", sans-serif;
  /* 
  font-family: "Kaushan Script", cursive;
  font-family: "Lato", sans-serif;
  font-family: "Poppins", sans-serif; */

  background: url("./assets/contour.svg");
  background-repeat: repeat-y;
  background-size: cover;
  background-position: center;
  background-color: #8b8b8b;
  height: 100%;
  overflow-y: scroll;
  max-width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgb(0, 0, 0) 15%,
    rgb(0, 0, 0) 85%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 20%;
}
.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 40s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.shadowClip {
  box-shadow: 2px -10px 20px 0px rgba(0, 0, 0, 0.55);
}

.blob1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  height: min-content;

  animation: blob1 20s infinite;
}

.blob2 {
  position: absolute;
  top: 50%;
  right: 0;
  width: 40%;
  height: min-content;

  animation: blob1 20s infinite;
  animation-delay: 5s;
}

@keyframes blob1 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(20%, 20%);
  }
  50% {
    transform: translate(15%, 50%);
  }
  75% {
    transform: translate(10%, 30%);
  }
  100% {
    transform: translate(0, 0);
  }
}

.splineScene {
  animation: fadein 2s linear;
  animation-delay: 0s;
}

.welcome {
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.code-editor {
  display: flex;
}

.line-numbers {
  flex-shrink: 0;
  padding-right: 1em;
  user-select: none;
}

.line-number {
  padding: 0;
}

.code {
  margin: 0;
  padding: 0;
  font-family: "Consolas", monospace;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
}
